/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
@import "theme/material/mat-theme";

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "node_modules/@techniek-team/common/scss/loading";

/* Custom style */
@import "theme/ionic/ion-card";
@import "theme/ionic/ion-content";
@import "theme/material/mat-table";
@import "theme/material/mat-form-field";
@import "theme/material/mat-tooltip";
@import "theme/shared-library/highlight-search-pipe";
@import "theme/shared-library/statistic-card";
@import "theme/sat-popover";

:root {
  color: var(--ion-text-color);
}

/** -----should be moved to style package------- **/
ion-select {
  --placeholder-opacity: 0.5;
}

ion-modal.modal-default:not(.overlay-hidden) ~ ion-modal.modal-default.stack-modal {
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32);
}

tt-modal {
  position: relative;
}

ion-modal {
  --width: fit-content;
}

button {
  background: none;
}
/** ------------ **/

// todo everything down here should not be in the global styling either because it's
//  dirty old or because it's should be held in a component instead of here.
.router-outlet-full-height + * {
  flex: 1 1 100%;
  box-sizing: border-box;
  -webkit-box-flex: 1;
  max-height: 100%;
}

.cdk-overlay-pane {
  //stylelint-disable-next-line declaration-no-important
  max-width: 95vw !important;
}

blockquote {
  margin:       20px 0;
  padding-left: 15px;
  border-left:  5px solid var(--lyceo-green);
}

.empty-list-message {
  color: rgba(var(--ion-text-color-rgb), 0.12);
  padding: 16px;
}

.badge {
  margin: 0 6px;
  padding:6px;
  text-align:center;
  background: var(--ion-color-medium-tint);
  color: var(--ion-text-color);
  box-sizing:border-box;
  white-space: nowrap;
  word-break: keep-all;
}

.scheduling-context-toolbar-icon-button {
  align-items: center;
  cursor: pointer;
  display: flex;

  i.material-icons {
    font-size: 36px;
  }
}

.blur {
  filter: blur(2px);
}

.heavy-blur {
  filter: blur(10px);
}
