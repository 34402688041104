:root {
  /** Unassigned (Openstaand) **/
  --ion-color-unassigned: #FDEAEC;
  --ion-color-unassigned-rgb: 253, 234, 236;
  --ion-color-unassigned-contrast: var(--lyceo-dark-blue);
  --ion-color-unassigned-contrast-rgb: var(--lyceo-dark-blue-rgb);
  --ion-color-unassigned-shade: #E4253B;
  --ion-color-unassigned-shade-contrast: var(--lyceo-red-contrast);
  --ion-color-unassigned-tint: var(--lyceo-red);

  /** waiting_for_documents (Wachtend op documenten) **/
  --ion-color-waiting-for-documents: #FDEAEC;
  --ion-color-waiting-for-documents-rgb: 253, 234, 236;
  --ion-color-waiting-for-documents-contrast: var(--lyceo-dark-blue);
  --ion-color-waiting-for-documents-contrast-rgb: var(--lyceo-dark-blue-rgb);
  --ion-color-waiting-for-documents-shade: #E4253B;
  --ion-color-waiting-for-documents-shade-contrast: var(--lyceo-red-contrast);
  --ion-color-waiting-for-documents-tint: var(--lyceo-red);

  /** waiting_for_customer_documents (Wachtend op documenten) **/
  --ion-color-waiting-for-customer-documents: #E6EAF5;
  --ion-color-waiting-for-customer-documents-rgb: 230, 234, 245;
  --ion-color-waiting-for-customer-documents-contrast: var(--lyceo-dark-blue);
  --ion-color-waiting-for-customer-documents-contrast-rgb: var(--lyceo-dark-blue-rgb);
  --ion-color-waiting-for-customer-documents-shade: var(--lyceo-dark-blue-70);
  --ion-color-waiting-for-customer-documents-shade-contrast: var(--lyceo-dark-blue-contrast);
  --ion-color-waiting-for-customer-documents-tint: var(--lyceo-dark-blue);

  /** waiting_for_confirmation (Onbevestigd) **/
  --ion-color-waiting-for-confirmation: #E6EAF5;
  --ion-color-waiting-for-confirmation-rgb: 230, 234, 245;
  --ion-color-waiting-for-confirmation-contrast: var(--lyceo-dark-blue);
  --ion-color-waiting-for-confirmation-contrast-rgb: var(--lyceo-dark-blue-rgb);
  --ion-color-waiting-for-confirmation-shade: var(--lyceo-dark-blue-70);
  --ion-color-waiting-for-confirmation-shade-contrast: var(--lyceo-dark-blue-contrast);
  --ion-color-waiting-for-confirmation-tint: var(--lyceo-dark-blue);

  /** draft (Concept) **/
  --ion-color-draft: #FBF5E6;
  --ion-color-draft-rgb: 251, 245, 230;
  --ion-color-draft-contrast: var(--lyceo-dark-blue);
  --ion-color-draft-contrast-rgb: var(--lyceo-dark-blue-rgb);
  --ion-color-draft-shade: #D69300;
  --ion-color-draft-shade-contrast: var(--lyceo-yellow-contrast);
  --ion-color-draft-tint: var(--lyceo-yellow);

  /** provisionally_confirmed (Voorlopig) **/
  --ion-color-provisionally-confirmed: #FBF5E6;
  --ion-color-provisionally-confirmed-rgb: 251, 245, 230;
  --ion-color-provisionally-confirmed-contrast: var(--lyceo-dark-blue);
  --ion-color-provisionally-confirmed-contrast-rgb: var(--lyceo-dark-blue-rgb);
  --ion-color-provisionally-confirmed-shade: #D69300;
  --ion-color-provisionally-confirmed-shade-contrast: var(--lyceo-yellow-contrast);
  --ion-color-provisionally-confirmed-tint: var(--lyceo-yellow);

  /** confirmed (Bevestigd) **/
  --ion-color-confirmed: #E8F6F5;
  --ion-color-confirmed-rgb: 232, 246, 245;
  --ion-color-confirmed-contrast: var(--lyceo-dark-blue);
  --ion-color-confirmed-contrast-rgb: var(--lyceo-dark-blue-rgb);
  --ion-color-confirmed-shade: #11A295;
  --ion-color-confirmed-shade-contrast: var(--lyceo-mint-contrast);
  --ion-color-confirmed-tint: var(--lyceo-mint);

  /** completed (wachtend op goedkeuring) **/
  --ion-color-completed: #E8F6F5;
  --ion-color-completed-rgb: 232, 246, 245;
  --ion-color-completed-contrast: var(--lyceo-dark-blue);
  --ion-color-completed-contrast-rgb: var(--lyceo-dark-blue-rgb);
  --ion-color-completed-shade: #11A295;
  --ion-color-completed-shade-contrast: var(--lyceo-mint-contrast);
  --ion-color-completed-tint: var(--lyceo-mint);

  /** approved (Goedgekeurd) **/
  --ion-color-approved: #E4E6E9;
  --ion-color-approved-rgb: 228, 230, 233;
  --ion-color-approved-contrast: var(--lyceo-dark-blue);
  --ion-color-approved-contrast-rgb: var(--lyceo-dark-blue-rgb);
  --ion-color-approved-shade: #848A98;
  --ion-color-approved-shade-contrast: var(--lyceo-dark-blue-contrast);
  --ion-color-approved-tint: #848A98;

  /** final (Afgerond) **/
  --ion-color-final: #E4E6E9;
  --ion-color-final-rgb: 228, 230, 233;
  --ion-color-final-contrast: var(--lyceo-dark-blue);
  --ion-color-final-contrast-rgb: var(--lyceo-dark-blue-rgb);
  --ion-color-final-shade: #848A98;
  --ion-color-final-shade-contrast: var(--lyceo-dark-blue-contrast);
  --ion-color-final-tint: #848A98;

  /** cancelled (Geannuleerd) **/
  --ion-color-cancelled: #E4E6E9;
  --ion-color-cancelled-rgb: 228, 230, 233;
  --ion-color-cancelled-contrast: var(--lyceo-dark-blue);
  --ion-color-cancelled-contrast-rgb: var(--lyceo-dark-blue-rgb);
  --ion-color-cancelled-shade: #848A98;
  --ion-color-cancelled-shade-contrast: var(--lyceo-dark-blue-contrast);
  --ion-color-cancelled-tint: #848A98;

  /** disabled (Conflict etc. niet bestaande state) **/
  --ion-color-disabled: #E4E6E9;
  --ion-color-disabled-rgb: 228, 230, 233;
  --ion-color-disabled-contrast: #A5A9B4; // --lyceo-dark-blue with 32 opacity
  --ion-color-disabled-contrast-rgb: 165, 169, 180;
  --ion-color-disabled-shade: #848A98;
  --ion-color-disabled-shade-contrast: var(--lyceo-dark-blue-contrast);
  --ion-color-disabled-tint: #848A98;
}

.ion-color-unassigned {
  //stylelint-disable-next-line declaration-no-important
  --ion-color-base: var(--ion-color-unassigned) !important;
  //stylelint-disable-next-line declaration-no-important
  --ion-color-base-rgb: var(--ion-color-unassigned-rgb) !important;
  //stylelint-disable-next-line declaration-no-important
  --ion-color-contrast: var(--ion-color-unassigned-contrast) !important;
  //stylelint-disable-next-line declaration-no-important
  --ion-color-contrast-rgb: var(--ion-color-unassigned-contrast-rgb) !important;
  //stylelint-disable-next-line declaration-no-important
  --ion-color-shade: var(--ion-color-unassigned-shade) !important;
  //stylelint-disable-next-line declaration-no-important
  --ion-color-shade-contrast: var(--ion-color-unassigned-shade-contrast) !important;
  //stylelint-disable-next-line declaration-no-important
  --ion-color-tint: var(--ion-color-unassigned-tint) !important;
}

.ion-color-waiting-for-documents {
  //stylelint-disable-next-line declaration-no-important
  --ion-color-base: var(--ion-color-waiting-for-documents) !important;
  //stylelint-disable-next-line declaration-no-important
  --ion-color-base-rgb: var(--ion-color-waiting-for-documents-rgb) !important;
  //stylelint-disable-next-line declaration-no-important
  --ion-color-contrast: var(--ion-color-waiting-for-documents-contrast) !important;
  //stylelint-disable-next-line declaration-no-important
  --ion-color-contrast-rgb: var(--ion-color-waiting-for-documents-contrast-rgb) !important;
  //stylelint-disable-next-line declaration-no-important
  --ion-color-shade: var(--ion-color-waiting-for-documents-shade) !important;
  //stylelint-disable-next-line declaration-no-important
  --ion-color-shade-contrast: var(--ion-color-waiting-for-documents-shade-contrast) !important;
  //stylelint-disable-next-line declaration-no-important
  --ion-color-tint: var(--ion-color-waiting-for-documents-tint) !important;
}

.ion-color-waiting-for-customer-documents {
  //stylelint-disable-next-line declaration-no-important
  --ion-color-base: var(--ion-color-waiting-for-customer-documents) !important;
  //stylelint-disable-next-line declaration-no-important
  --ion-color-base-rgb: var(--ion-color-waiting-for-customer-documents-rgb) !important;
  //stylelint-disable-next-line declaration-no-important
  --ion-color-contrast: var(--ion-color-waiting-for-customer-documents-contrast) !important;
  //stylelint-disable-next-line declaration-no-important
  --ion-color-contrast-rgb: var(--ion-color-waiting-for-customer-documents-contrast-rgb) !important;
  //stylelint-disable-next-line declaration-no-important
  --ion-color-shade: var(--ion-color-waiting-for-customer-documents-shade) !important;
  //stylelint-disable-next-line declaration-no-important
  --ion-color-shade-contrast: var(--ion-color-waiting-for-customer-documents-shade-contrast) !important;
  //stylelint-disable-next-line declaration-no-important
  --ion-color-tint: var(--ion-color-waiting-for-customer-documents-tint) !important;
}

.ion-color-waiting-for-confirmation {
  //stylelint-disable-next-line declaration-no-important
  --ion-color-base: var(--ion-color-waiting-for-confirmation) !important;
  //stylelint-disable-next-line declaration-no-important
  --ion-color-base-rgb: var(--ion-color-waiting-for-confirmation-rgb) !important;
  //stylelint-disable-next-line declaration-no-important
  --ion-color-contrast: var(--ion-color-waiting-for-confirmation-contrast) !important;
  //stylelint-disable-next-line declaration-no-important
  --ion-color-contrast-rgb: var(--ion-color-waiting-for-confirmation-contrast-rgb) !important;
  //stylelint-disable-next-line declaration-no-important
  --ion-color-shade: var(--ion-color-waiting-for-confirmation-shade) !important;
  //stylelint-disable-next-line declaration-no-important
  --ion-color-shade-contrast: var(--ion-color-waiting-for-confirmation-shade-contrast) !important;
  //stylelint-disable-next-line declaration-no-important
  --ion-color-tint: var(--ion-color-waiting-for-confirmation-tint) !important;
}

.ion-color-draft {
  //stylelint-disable-next-line declaration-no-important
  --ion-color-base: var(--ion-color-draft) !important;
  //stylelint-disable-next-line declaration-no-important
  --ion-color-base-rgb: var(--ion-color-draft-rgb) !important;
  //stylelint-disable-next-line declaration-no-important
  --ion-color-contrast: var(--ion-color-draft-contrast) !important;
  //stylelint-disable-next-line declaration-no-important
  --ion-color-contrast-rgb: var(--ion-color-draft-contrast-rgb) !important;
  //stylelint-disable-next-line declaration-no-important
  --ion-color-shade: var(--ion-color-draft-shade) !important;
  //stylelint-disable-next-line declaration-no-important
  --ion-color-shade-contrast: var(--ion-color-draft-shade-contrast) !important;
  //stylelint-disable-next-line declaration-no-important
  --ion-color-tint: var(--ion-color-draft-tint) !important;
}

.ion-color-provisionally-confirmed, .ion-color-provisionally_confirmed {
  //stylelint-disable-next-line declaration-no-important
  --ion-color-base: var(--ion-color-provisionally-confirmed) !important;
  //stylelint-disable-next-line declaration-no-important
  --ion-color-base-rgb: var(--ion-color-provisionally-confirmed-rgb) !important;
  //stylelint-disable-next-line declaration-no-important
  --ion-color-contrast: var(--ion-color-provisionally-confirmed-contrast) !important;
  //stylelint-disable-next-line declaration-no-important
  --ion-color-contrast-rgb: var(--ion-color-provisionally-confirmed-contrast-rgb) !important;
  //stylelint-disable-next-line declaration-no-important
  --ion-color-shade: var(--ion-color-provisionally-confirmed-shade) !important;
  //stylelint-disable-next-line declaration-no-important
  --ion-color-shade-contrast: var(--ion-color-provisionally-confirmed-shade-contrast) !important;
  //stylelint-disable-next-line declaration-no-important
  --ion-color-tint: var(--ion-color-provisionally-confirmed-tint) !important;
}

.ion-color-confirmed {
  //stylelint-disable-next-line declaration-no-important
  --ion-color-base: var(--ion-color-confirmed) !important;
  //stylelint-disable-next-line declaration-no-important
  --ion-color-base-rgb: var(--ion-color-confirmed-rgb) !important;
  //stylelint-disable-next-line declaration-no-important
  --ion-color-contrast: var(--ion-color-confirmed-contrast) !important;
  //stylelint-disable-next-line declaration-no-important
  --ion-color-contrast-rgb: var(--ion-color-confirmed-contrast-rgb) !important;
  //stylelint-disable-next-line declaration-no-important
  --ion-color-shade: var(--ion-color-confirmed-shade) !important;
  //stylelint-disable-next-line declaration-no-important
  --ion-color-shade-contrast: var(--ion-color-confirmed-shade-contrast) !important;
  //stylelint-disable-next-line declaration-no-important
  --ion-color-tint: var(--ion-color-confirmed-tint) !important;
}

.ion-color-completed {
  //stylelint-disable-next-line declaration-no-important
  --ion-color-base: var(--ion-color-completed) !important;
  //stylelint-disable-next-line declaration-no-important
  --ion-color-base-rgb: var(--ion-color-completed-rgb) !important;
  //stylelint-disable-next-line declaration-no-important
  --ion-color-contrast: var(--ion-color-completed-contrast) !important;
  //stylelint-disable-next-line declaration-no-important
  --ion-color-contrast-rgb: var(--ion-color-completed-contrast-rgb) !important;
  //stylelint-disable-next-line declaration-no-important
  --ion-color-shade: var(--ion-color-completed-shade) !important;
  //stylelint-disable-next-line declaration-no-important
  --ion-color-shade-contrast: var(--ion-color-completed-shade-contrast) !important;
  //stylelint-disable-next-line declaration-no-important
  --ion-color-tint: var(--ion-color-completed-tint) !important;
}

.ion-color-approved {
  //stylelint-disable-next-line declaration-no-important
  --ion-color-base: var(--ion-color-approved) !important;
  //stylelint-disable-next-line declaration-no-important
  --ion-color-base-rgb: var(--ion-color-approved-rgb) !important;
  //stylelint-disable-next-line declaration-no-important
  --ion-color-contrast: var(--ion-color-approved-contrast) !important;
  //stylelint-disable-next-line declaration-no-important
  --ion-color-contrast-rgb: var(--ion-color-approved-contrast-rgb) !important;
  //stylelint-disable-next-line declaration-no-important
  --ion-color-shade: var(--ion-color-approved-shade) !important;
  //stylelint-disable-next-line declaration-no-important
  --ion-color-shade-contrast: var(--ion-color-approved-shade-contrast) !important;
  //stylelint-disable-next-line declaration-no-important
  --ion-color-tint: var(--ion-color-approved-tint) !important;
}

.ion-color-final {
  //stylelint-disable-next-line declaration-no-important
  --ion-color-base: var(--ion-color-final) !important;
  //stylelint-disable-next-line declaration-no-important
  --ion-color-base-rgb: var(--ion-color-final-rgb) !important;
  //stylelint-disable-next-line declaration-no-important
  --ion-color-contrast: var(--ion-color-final-contrast) !important;
  //stylelint-disable-next-line declaration-no-important
  --ion-color-contrast-rgb: var(--ion-color-final-contrast-rgb) !important;
  //stylelint-disable-next-line declaration-no-important
  --ion-color-shade: var(--ion-color-final-shade) !important;
  //stylelint-disable-next-line declaration-no-important
  --ion-color-shade-contrast: var(--ion-color-final-shade-contrast) !important;
  //stylelint-disable-next-line declaration-no-important
  --ion-color-tint: var(--ion-color-final-tint) !important;
}

.ion-color-cancelled {
  //stylelint-disable-next-line declaration-no-important
  --ion-color-base: var(--ion-color-cancelled) !important;
  //stylelint-disable-next-line declaration-no-important
  --ion-color-base-rgb: var(--ion-color-cancelled-rgb) !important;
  //stylelint-disable-next-line declaration-no-important
  --ion-color-contrast: var(--ion-color-cancelled-contrast) !important;
  //stylelint-disable-next-line declaration-no-important
  --ion-color-contrast-rgb: var(--ion-color-cancelled-contrast-rgb) !important;
  //stylelint-disable-next-line declaration-no-important
  --ion-color-shade: var(--ion-color-cancelled-shade) !important;
  //stylelint-disable-next-line declaration-no-important
  --ion-color-shade-contrast: var(--ion-color-cancelled-shade-contrast) !important;
  //stylelint-disable-next-line declaration-no-important
  --ion-color-tint: var(--ion-color-cancelled-tint) !important;
}

.ion-color-disabled {
  //stylelint-disable-next-line declaration-no-important
  --ion-color-base: var(--ion-color-disabled) !important;
  //stylelint-disable-next-line declaration-no-important
  --ion-color-base-rgb: var(--ion-color-disabled-rgb) !important;
  //stylelint-disable-next-line declaration-no-important
  --ion-color-contrast: var(--ion-color-disabled-contrast) !important;
  //stylelint-disable-next-line declaration-no-important
  --ion-color-contrast-rgb: var(--ion-color-disabled-contrast-rgb) !important;
  //stylelint-disable-next-line declaration-no-important
  --ion-color-shade: var(--ion-color-disabled-shade) !important;
  //stylelint-disable-next-line declaration-no-important
  --ion-color-shade-contrast: var(--ion-color-disabled-shade-contrast) !important;
  //stylelint-disable-next-line declaration-no-important
  --ion-color-tint: var(--ion-color-disabled-tint) !important;
}
